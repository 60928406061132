body {
  color: #fff;
  background-color: #000;
}

#app {
  height: 90vh;
  margin: 0;
}

.home {
  text-align: center;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Oswald;
  display: flex;
}

h1, h2 {
  margin-block-start: 0;
}

h1 {
  margin-block-end: 0;
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
  font-weight: 200;
}

a {
  color: #fff;
  margin: .5rem auto;
  font-size: 1.3rem;
  text-decoration: none;
  transition: all .35s;
}

a:hover {
  color: #ccc;
}

a:first-of-type {
  margin-bottom: 2rem;
  font-size: 1.3rem;
  font-weight: 200;
}

/*# sourceMappingURL=index.5068a095.css.map */
