body {
  background-color: black;
  color: white;
}

#app {
  height: 90vh;
  margin: 0;
}

.home {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Oswald";
  height: 100%;
}

h1,
h2 {
  margin-block-start: 0;
}

h1 {
  margin-block-end: 0;
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
  font-weight: 200;
}

a {
  text-decoration: none;
  color: white;
  font-size: 1.3rem;
  margin: 0.5rem auto;

  transition: 0.35s all ease;

  &:hover {
    color: darken(white, 20);
  }

  &:first-of-type {
    margin-bottom: 2rem;
    font-weight: 200;
    font-size: 1.3rem;
  }
}
